.logo {
    height: 7em;
    width: 18em;
    margin-left: -2rem;
    margin-top: .8rem;
    margin-bottom: .8rem;
    margin-right: 1rem;
}

.appBar {
    background-color: #212529;
}