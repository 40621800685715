.textForm {
    margin-left: 4rem;
    font-family: 'Raleway', sans-serif;
    text-align: left;
    font-size: 1.25rem;
    font-weight: '300';
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#firstDiv {
    margin-top: 3.5rem;
}